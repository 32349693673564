
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// FontAwesome
//@import "node_modules/font-awesome/scss/font-awesome";

// Select2
//@import 'node_modules/select2/src/scss/core';

// Select2
//@import "node_modules/select2/dist/css";

// FontAwesome
//@import "../../../node_modules/font-awesome/scss/font-awesome";

// Select2
//@import "node_modules/select2/dist/css";

// Datetimepicker
//@import '../vendor/eonasdan-bootstrap-datetimepicker/src/sass';

// Dropzone
//@import '../../../node_modules/dropzone/dist/dropzone';


// Novigation Search
.container__article {
  padding: 0.5em 0;
}
.big_width {
  width: 200px !important;
}
.middle_width {
  width: 180px !important;
}
.small_width {
  width: 150px !important;
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}
.padding_right_15 {
  padding-right: 15px;
  margin-bottom: 5px;
}
#search-key .btn-primary {
  background-color: #F38630 !important;
  border-color: #F38630 !important;
}
#search-key .btn-primary:hover {
  background-color: #FA6900 !important;
  border-color: #FA6900 !important;
}
#search-key .btn {
  vertical-align: top;
}

.container {
  width: 95% !important;
}

body {
  //font-family: "Raleway", sans-serif;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px !important;
  line-height: 1.6 !important;
  color: #636b6f !important;
  background-color: #e3e7ea !important;
}

.form-group {
  margin-bottom: 0px;
  height: 80px;
}

.form-control {
  border-radius: 0px;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 14px;
  }
}

.table > thead > tr > th {
  line-height: 1.6;
  background: #bdbdbd;
  color: #fff;
}

.navbar {
  margin-bottom: 0px;
}

.navbar-brand {
  float: left;
  padding: 6px 5px;
  height: 55px;
  margin-right: 30px;
}
.navbar-default {
  background-color: #1b2831;
  border-color: #d3e0e9;
}
.navbar-default .navbar-nav > li > a {
  font-size: 17px;
  color: #90a1a8;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #fff;
  background-color: transparent;
}
.navbar-default .navbar-nav > li > a:active {
  color: #fff;
}
.navbar-nav > li.current a {
  color: #fff;
}
.navbar-nav > li > a {
  line-height: 37px;
}

.btn {
  border-radius: 0px;
}

.input-group-addon {
  border-radius: 0px;
}
.page-header {
  margin: 45px 0 15px;
  padding-bottom: 5px;
}
h4 {
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 11px;
  margin-bottom: 5px;
}
a {
  color: #444;
  text-decoration: none !important;
}
.media {
  margin-top: 10px;
}
.navbar-default .navbar-nav > li > a {
  font-weight: 400;
  font-size: 17px;
}
.nav > li > a {
  padding: 15px 20px;
}
.select2-dropdown,
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid $input-border;
  border-radius: 4px;
}

.select2-container,
.select2-container--default .select2-selection--multiple {
  font-size: $font-size-base;
}

.form-control[disabled] {
  background-color: #f9f9f9;
}

.dropzone {
  border: 1px solid $input-border;
  border-radius: 4px;
}

.dropzone {
  display: none;
}

label[for=my-dropzone]>small {
  cursor: pointer;
}
.preview__content {
  display: none;
  margin-top: $font-size-base * 1.6;
  height: auto;
  @extend .form-control;
  box-shadow: none;
}
.table tbody tr {
  border-top: 1px solid #f5f5f5;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #ccd0d2;
  padding: 8px;
  line-height: 1.6;
}
.btn {
  border-radius: 0px;
}
.red {
  color: #b82420 ;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.media, .media-body {
  overflow: visible;
}
.table > tbody > tr > td {
  padding: 10px;
  line-height: 26px;
  border-top: none;
}

// Application Layouts
.container__article{
  padding: 1em 0;
}

.action__article {
  margin-top: 2em;
}

.footer__master {
  margin-top: 1em;

  a {
    display: inline-block;
    color: $text-color;
    text-decoration: none;
    padding: 0 5px;
  }

  ul {
    li {
      padding: 0;

      &:first-child {
        margin-right: 5px;
      }
    }

    li:not(:first-child) {
      a:hover,
      a:active,
      a:focus {
        border-radius: 3px;
        background-color: darken($body-bg, 5%);
        transition: all 0.3s ease;
      }

      &.active > a {
        border-radius: 3px;
        background-color: $body-bg;
      }
    }
  }
}

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 5px;
  right: 5px;
  display: none;
  z-index: 9998;
  transition: all 0.5s ease-in-out;
  padding: 8px 10px;
}

// Sidebar
.sidebar__article,
.tags__article,
.attachments__article {
  a {
    color: $text-color;
    text-decoration: none;
  }
}

.sidebar__article {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    a {
      padding: 0.5rem;
      display: block;
    }

    a:hover,
    a:active,
    a:focus {
      border-radius: 3px;
      background-color: darken($body-bg, 5%);
      transition: all 0.3s ease;
    }

    &.active>a {
      border-radius: 3px;
      background-color: darken($body-bg, 10%);
    }
  }

  .badge {
    float: right;
    margin-right: 1rem;
  }

  form {
    margin-bottom: 30px;
  }
}

// Article
.content__article {
  margin: 2em;
  img {
    display: block;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
    transition: all .2s ease-in-out
  }
}

// Tags Line, Attachments Line
.tags__article,
.attachments__article {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 100;
  font-size: $font-size-base * 0.8;

  li {
    display: inline-block;
  }

  li:first-child {
    margin-right: 10px;
  }

  li:not(:first-child) {
    padding: 4px 8px;
    background-color: darken($body-bg, 5%);
    border-radius: 3px;

    &:hover {
      background-color: darken($body-bg, 10%);
    }
  }
}

// Comments
.link__login__comment {
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 3px;
  border: 1px solid $input-border;
  width: 100%;
}

.item__comment.top {
  padding: 1rem;
  border-radius: 0px;
  position: relative;
  overflow: visible;
  float: right;
  width: 100%;
  border: 1px solid $input-border;
  background: #f1f1f1;
}

.media__edit__comment,
.media__create__comment.sub {
  display: none;
}

.form__new__comment,
.list__comment {
  .form-group {
    width: 100%;
    margin: auto;
  }

  div.text-right {
    margin: 10px auto;
  }
}

.content__comment {
  p {
    margin: 0;
  }
}

.action__comment {
  button, span {
    font-size: $font-size-base * 0.8;
    font-weight: 400;
    text-decoration: none;
  }

  button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }

  button:hover,
  button:active,
  button:focus {
    transition: all .2s ease-in-out
  }

  &>span {
    margin: 0 5px;
  }
}

// Form
.form-control {
  box-shadow: none;
}

.form__auth {
  max-width: 400px;
  width: 100%;
  padding: 15px;
  margin: 0 auto;

  .checkbox {
    margin-bottom: 1rem;
  }

  .checkbox {
    font-weight: normal;
  }

  .form-control {
    position: relative;
    height: auto;
    box-sizing: border-box;
    padding: 0.8rem;
  }

  .form-control:focus {
    z-index: 2;
    border-color: $input-border;
    box-shadow: none;
  }

  input {
    margin-bottom: 10px;
  }

  .login-or {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .span-or {
    display: block;
    position: absolute;
    left: 50%;
    top: -1px;
    margin-left: -25px;
    background-color: $body-bg;
    width: 50px;
    text-align: center;
  }

  .hr-or {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .fa-github {
    margin-right: 10px;
  }
}

span.form-error {
  margin-top: 1px;
  display: block;
  font-size: 0.8em;
  color: $brand_danger;
  font-style: italic;
  font-weight: 100
}

// Alert, Flash Messages
.alert {
  display: inline-block;
  position: fixed;
  bottom: 50px;
  right: 15px;
  max-width: 450px;
  opacity: .8;
  z-index: 999;
}

// Markdown Viewer
.docs__sidebar {
  margin-top: 20px;
}

#app-layout {
  pre {
    background: #000000;
    border-radius: 0;
  }

  // 이하 데일 리즈의 칼라 스킴에서 복사한 스타일시트

  .hljs {
    display: block;
    padding: 0.5em;
    background: #000000;
    color: #BABABA;
  }

  .hljs-comment,
  .hljs-template_comment,
  .hljs-javadoc {
    color: #494B4D;
  }

  .hljs-keyword,
  .ruby .hljs-function .hljs-keyword,
  .hljs-request,
  .hljs-status,
  .nginx .hljs-title {
    color: #F08D24;
  }

  .hljs-function .hljs-keyword,
  .hljs-sub .hljs-keyword,
  .method,
  .hljs-list .hljs-title {
    color: #68C244;
  }

  .hljs-string,
  .hljs-tag .hljs-value,
  .hljs-cdata,
  .hljs-filter .hljs-argument,
  .hljs-attr_selector,
  .apache .hljs-cbracket,
  .hljs-date,
  .tex .hljs-command,
  .coffeescript .hljs-attribute {
    color: #F2D42C;
  }

  .hljs-subst {
    color: #DAEFA3;
  }

  .hljs-regexp {
    color: #E9C062;
  }

  .hljs-title,
  .hljs-sub .hljs-identifier,
  .hljs-pi,
  .hljs-tag,
  .hljs-tag .hljs-keyword,
  .hljs-decorator,
  .hljs-shebang,
  .hljs-prompt {
    color: #E8341C;
  }

  .hljs-symbol,
  .ruby .hljs-symbol .hljs-string,
  .hljs-number {
    color: #8E69C9;
  }

  .hljs-params,
  .hljs-variable,
  .clojure .hljs-attribute {
    color: #1CC3E8;
  }

  .css .hljs-tag,
  .hljs-rules .hljs-property,
  .hljs-pseudo,
  .tex .hljs-special {
    color: #E8341C;
  }

  .css .hljs-class {
    color: #1CC3E8;
  }

  .hljs-rules .hljs-keyword {
    color: #1CC3E8;
  }

  .hljs-rules .hljs-value {
    color: #E8341C;
  }

  .css .hljs-id {
    color: #8B98AB;
  }

  .hljs-annotation,
  .apache .hljs-sqbracket,
  .nginx .hljs-built_in {
    color: #9B859D;
  }

  .hljs-preprocessor,
  .hljs-pragma {
    color: #8996A8;
  }

  .hljs-hexcolor,
  .css .hljs-value .hljs-number {
    color: #DD7B3B;
  }

  .css .hljs-function {
    color: #DAD085;
  }

  .diff .hljs-header,
  .hljs-chunk,
  .tex .hljs-formula {
    background-color: #0E2231;
    color: #F8F8F8;
    font-style: italic;
  }

  .diff .hljs-change {
    background-color: #4A410D;
    color: #F8F8F8;
  }

  .hljs-addition {
    background-color: #253B22;
    color: #F8F8F8;
  }

  .hljs-deletion {
    background-color: #420E09;
    color: #F8F8F8;
  }

  .coffeescript .javascript,
  .javascript .xml,
  .tex .hljs-formula,
  .xml .javascript,
  .xml .vbscript,
  .xml .css,
  .xml .hljs-cdata {
    opacity: 0.5;
  }

  // 이상 데일 리즈의 칼라 스킴에서 복사한 스타일시트
}



// 커스텀 CSS for BMS
.navbar-nav > li > a {
  line-height: 45px !important;
}
.navbar-default .navbar-nav > li > a:hover {
  font-weight: 400;
  font-size: 17px;
  background: #3f4457;
}
#master-table tr:hover {
  background-color: #f1f1f1;
}
.view-tranx {
  padding: 7px 16px;
  font-size: 13px;
  margin-top: 7px;
  display: inline-flex;
  line-height: 1.2em !important;
  color: #fff;
  background: #69D2E7;
  border-radius: 2px;
  border-bottom: 1px solid rgba(0,0,0,.05);
  white-space: nowrap;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #8eb4cb;
  border-color: #7da8c3;
}
.view-tranx a, .send-email a {
  color: #fff;
  text-decoration: none !important;
}
.normal-status {
  padding: 7px 16px;
  font-size: 13px;
  margin-top: 7px;
  display: inline-flex;
  line-height: 1.2em !important;
  color: #fff;
  background: #B9D7D9;
  border-radius: 2px;
  border-bottom: 1px solid rgba(0,0,0,.05);
  white-space: nowrap;
  width: 105px;
  text-align: center !important;
}
.assigned-status {
  background: #2579a9 !important;
  color: #fff !important;
  width: 105px;
}
.cancel-status {
  background: #bf5329 !important;
  color: #fff !important;
  width: 105px;
}
table.table {
  width: auto;
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #e3e7ea;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  min-height: 550px;
}

input[type="checkbox"] {
  border: 1px solid #b4b9be;
  background: #fff;
  color: #555;
  clear: none;
  display: inline-block;
  line-height: 0;
  height: 16px;
  margin: -4px 4px 0 0;
  outline: 0;
  padding: 0!important;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  min-width: 16px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  transition: .05s border-color ease-in-out;
}

html {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.table {
  border: none;
}

.table-definition thead th:first-child {
  pointer-events: none;
  background: white;
  border: none;
}

.table td {
  vertical-align: middle;
}

.page-item > * {
  border: none;
}

.custom-checkbox {
  min-height: 1rem;
  padding-left: 0;
  margin-right: 0;
  cursor: pointer;
}
.custom-checkbox .custom-control-indicator {
  content: "";
  display: inline-block;
  position: relative;
  width: 30px;
  height: 10px;
  background-color: #818181;
  border-radius: 15px;
  margin-right: 10px;
  -webkit-transition: background .3s ease;
  transition: background .3s ease;
  vertical-align: middle;
  margin: 0 16px;
  box-shadow: none;
}
.custom-checkbox .custom-control-indicator:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #f1f1f1;
  border-radius: 21px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -2px;
  top: -4px;
  -webkit-transition: left .3s ease, background .3s ease, box-shadow .1s ease;
  transition: left .3s ease, background .3s ease, box-shadow .1s ease;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #84c7c1;
  background-image: none;
  box-shadow: none !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator:after {
  background-color: #84c7c1;
  left: 15px;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none !important;
}
// End of 커스텀CSS

// 미디어 쿼리
@media screen and (max-width: 991px) {
  .action__article {
    margin-bottom: 1em;
  }

  .sidebar__article {
    margin-bottom: 2em;
  }

  .item__comment {
    &>a.pull-left {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 479px) {

}


@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}


.disabled_css {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #f9f9f9;
}